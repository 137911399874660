<template>
	<div class="page">
		<div class="container">
			<div class="box">
				<div class="title" @click="go('/member')">会员扫码登录</div>
				<img :src="code" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import HelloWorld from '@/components/HelloWorld.vue'

	export default {
		name: 'Home',
		components: {
			HelloWorld
		},
		data() {
			return {
				code: require("@/assets/images/code.png")
			}
		},
		methods: {
			go(url) {
				this.$router.push({
					path: url,
				})
			}
		}
	}
</script>


<style scoped lang="scss">
	.page {

		.top {
			color: #fff;
		}

		.box {
			width: 800px;
			margin: 0 auto;
			margin-top: 100px;

			.title {
				text-align: center;
				font-size: 40px;
				color: #fff;
			}

			img{
				display: block;
				margin: 50px auto;
			}


		}

     .icon-fanhui{
		 font-size: 30px;
		 cursor: pointer;
	 }

	}
</style>
